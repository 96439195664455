import IAction from '../types/actions';
import IMenu from './types';
import ActionsEnum from './types/actions';

const reducer = (state: IMenu, action: IAction): IMenu => {
    switch (action.type) {
        case ActionsEnum.set:
            return ({
                ...state,
                ...action.payload,
            });
        default:
            return state;
    }
};

export default reducer;
