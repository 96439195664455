import frameReducer from './frame/reducer';
import menuReducer from './menu/reducer';
import messageReducer from './message/reducer';
import IAction from './types/actions';
import IState from './types/state';

const reducer = (state: IState, action: IAction): IState => ({
    ...frameReducer({frame: state.frame}, action),
    ...messageReducer({message: state.message}, action),
    ...menuReducer({menu: state.menu}, action),
});

export default reducer;
