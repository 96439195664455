import IAction from '../types/actions';
import initialState from './initialState';
import IMessage from './types';
import ActionsEnum from './types/actions';

const reducer = (state: IMessage, action: IAction): IMessage => {
    switch (action.type) {
        case ActionsEnum.save:
            return ({
                ...state,
                ...action.payload,
            });
        case ActionsEnum.remove:
            return ({
                ...state,
                ...initialState,
            });
        default:
            return state;
    }
};

export default reducer;
