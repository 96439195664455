import IAction from '../types/actions';
import IFrame from './types';
import ActionsEnum from './types/actions';

const reducer = (state: IFrame, action: IAction): IFrame => {
    switch (action.type) {
        case ActionsEnum.save:
            return ({
                ...state,
                ...action.payload,
            });
        default:
            return state;
    }
};

export default reducer;
